<template>
  <div class="relative" :class="{'z-10': isTranscriptMode}">
    <div class="bg-primary-300 pr-2.5">
      <VueSlider
        id="playerSeeker"
        key="playerSeeker"
        v-model="progressOutOf100"
        style="height:2px;padding: 0;width: 100%;"
        :railStyle="{'cursor': 'pointer', 'background-color': '#E5E7EB'}"
        :processStyle="{'background-color': isTranscriptMode ? '#E2462C' : '#000228'}"
        :tooltip="'none'"
        :interval="0.1"
      >
        <template v-slot:dot="">
          <div :class="'custom-dot'" />
        </template>
      </VueSlider>
    </div>

    <div
      class="transition-all duration-500 ease-in-out flex font-h font-medium mt-2 text-xs px-2 text-opacity-60 items-center justify-between"
      :class="isTranscriptMode ? 'text-white' : 'text-primary'"
    >
      <span>{{humanCurrentTime}}</span>
      <span>{{humanTotalTime}}</span>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import VueSlider from 'vue-slider-component'
import 'vue-slider-component/theme/default.css'
import { humanLength } from '@/utilities/utility'

export default {
  props: {
    currentTime: {
      type: Number,
      default: 0
    },
    totalTime: {
      type: Number,
      default: 0
    }
  },
  components: {
    VueSlider
  },
  computed: {
    ...mapGetters({
      isTranscriptMode: 'editor/isTranscriptMode'
    }),
    humanCurrentTime () {
      return humanLength(this.currentTime)
    },
    humanTotalTime () {
      return humanLength(this.totalTime)
    },
    progressOutOf100: {
      get() {
        if (this.currentTime && this.totalTime) {
          if (this.currentTime >= this.totalTime) return 100
          return (this.currentTime / this.totalTime) * 100
        }
        return 0
      },
      set(newVal) {
        const seekedTime = this.totalTime * newVal / 100
        this.goToTime(seekedTime)
      }
    }
  },
  methods: {
    goToTime (index) {
      this.$emit('goToTime', index)
    },
    humanLength(time) {
      return humanLength(time)
    }
  }
}
</script>

<style lang="scss" scoped>
.custom-dot {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  box-shadow: 0px 0px 14px -3.5px rgba(0, 0, 0, 0.61);
  background-color: #fff;
  transition: all .3s;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  top:2px;
  left: 7px;
}

.custom-dot::before {
  content: '';
  display: inline-block;

  width: 8px;
  height: 8px;
  border-radius: 50%;
  background: #E2462C;
}
</style>
